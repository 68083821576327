import axios from 'axios';
import store from '@/store';
import authHeader from '@/services/auth.header';
window.axios = axios;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
//console.log("backend url", process.env.VUE_APP_BACKEND_URL)
axios.interceptors.request.use(function(config) {
    const headers = authHeader();
    //combine headers
    const currentHeaders = config.headers;
    config.headers = {
        ...currentHeaders,
        ...headers
    }
    
    // Establecer estado de carga en true
    console.log("start url", config.url)
    store.dispatch('setLoading', { url: config.url, isLoading: true });

    return config;
})


axios.interceptors.response.use(function(response) {
    // Establecer estado de carga en false
    console.log("stop url", response.config.url)
    store.dispatch('setLoading', { url: response.config.url, isLoading: false });

    return response;
}, function(error) {
    // Establecer estado de carga en false
    console.log("stop error url", error)
    const url = error?.config?.url;
    console.log("stop error url", url)
    if (url) {
        store.dispatch('setLoading', { url, isLoading: false });
    }

    return Promise.reject(error);
});

export default axios;
