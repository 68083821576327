import settings from '@/assets/settings.json';

const s3Url = process.env.VUE_APP_S3_URL;

export const getSettings = () => {
  return settings;
};

export const getSetting = (key) => {
  const value = settings.find(setting => setting.name === key)?.value ?? 'Setting not found';
  if (value === 'Setting not found') {
    console.error('Setting not found:', key);
  }
  if (value.startsWith('houxe')) {
    return s3Url + '/' +value;
  }
  if (value.startsWith('[houxe')) {
    const images = value.replace('[', '').replace(']', '').split(',');
    return images.map(image => s3Url + '/' + image.trim());
  }
  return value;
}
